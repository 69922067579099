<template>
  <div>
    <div
      class="text-right"
      style="height: 0"
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="btn-icon-kanban btn-icon mr-1"
        to="/leads-table"
      >
        <feather-icon icon="GridIcon" />
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="btn-icon-kanban btn-icon"
        to="/leads-kanban"
      >
        <feather-icon icon="ColumnsIcon" />
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
  },
};
</script>

<style scoped>
.btn-icon-kanban {
  transform: translateY(-55px);
}
</style>
